<template lang="pug">
    section#experience
        img(src="@images/objetos/envelope.png").envelope
        img(src="@images/objetos/sketchbook.png").sketch
        img(src="@images/objetos/garrafa.png").garrafa
        .content
            h2 Experience
            .text
                p Quanto tempo esperamos para nos encontrar novamente, não foi?
                p Mas a espera acabou! Vamos pegar a estrada para celebrar a liberdade junto à natureza e seus elementos que só seu Land Rover pode proporcionar, desbravando as trilhas de Campo Magro, com destino à São Luiz do Purunã.
                p Será uma experiência gastronômica única em meio à natureza.
                p Um encontro de terra, água, fogo e ar.
</template>

<script>
export default {
    name: "section-experience",
    mounted() {
        setTimeout(() => {
            const scenes = []
            scenes.push(this.$scrollmagic.scene({
                triggerElement: '#experience',
                triggerHook: 0.5,
                duration: 500
            }).setTween('#background .bg-experience', { 
                css: { 
                    transform: `translateX(-50%)`,
                }
            }))

            scenes.push(this.$scrollmagic.scene({
                triggerElement: '#experience',
                triggerHook: 0.5,
                duration: 300
            }).setTween('#experience .envelope', { 
                css: { 
                    transform: `translateY(0)`,
                    opacity: 1
                }
            }))

            scenes.push(this.$scrollmagic.scene({
                triggerElement: '#experience',
                triggerHook: 0.3,
                duration: 300
            }).setTween('#experience .sketch', { 
                css: { 
                    transform: `translateY(0)`,
                    opacity: 1
                }
            }))

            scenes.push(this.$scrollmagic.scene({
                triggerElement: '#experience',
                triggerHook: 0.3,
                duration: 400
            }).setTween('#experience .garrafa', { 
                css: { 
                    transform: `translateY(0)`,
                    opacity: 1
                }
            }))

            scenes.push(this.$scrollmagic.scene({
                triggerElement: '#experience',
                triggerHook: 0.9,
                duration: 100
            }).setTween('#background .rope', { 
                css: {
                    opacity: 0
                }
            }))

            scenes.push(this.$scrollmagic.scene({
                triggerElement: '#experience',
                triggerHook: 0.8,
                duration: 500
            }).setTween('#background .rope', { 
                css: {
                    left: `90%`
                }
            }))

            scenes.push(this.$scrollmagic.scene({
                triggerElement: '#experience',
                triggerHook: 0.5,
                duration: 200
            }).setTween('#background .rope', { 
                css: {
                    opacity: 1
                }
            }))

            scenes.push(this.$scrollmagic.scene({
                triggerElement: '#experience',
                triggerHook: 0.5,
                duration: 500
            }).setTween('#background .compass', { 
                css: {
                    right: window.innerWidth > 1366 ? `20%` : `15%`,
                    bottom: `60%`
                }
            }))

            scenes.push(this.$scrollmagic.scene({
                triggerElement: '#experience',
                triggerHook: 0,
                duration: 100
            }).setTween('#background .compass', { 
                css: {
                    opacity: 0
                }
            }))

            scenes.forEach(scene => this.$scrollmagic.addScene(scene))
        }, 200)
    },
}
</script>

<style lang="stylus" scoped src="./Experience.styl"></style>